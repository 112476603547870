@import "../../../../../assets/scss/variable";

.tableRow {
  display:               grid;
  grid-template-columns: minmax(10rem, 50%) minmax(13.75rem, 50%);
  padding-right:         10%;
  margin:                1.5rem 0;
  min-height:            2.5rem;
  align-items:           center;
  .field {
    position: relative;
    .validation {
      position:  absolute;
      color:     $redColor;
      bottom:    -1rem;
      left:      0;
      z-index:   1;
      font-size: 0.75rem;
    }
  }
  .networkValue {
    width: 100%;
  }
  .select {
    background:    #f2f4f8;
    padding:       0 0.6rem 0 4rem;
    border:        none;
    border-radius: 0.625rem;
    height:        3rem;
    width:     100%;
    select {
      background: transparent;
    }
  }
  .select, input {
    padding:         0;
    text-align:      center;
    text-align-last: center;
  }
  &.link a {
    color:           #009cff;
    text-decoration: underline;
    &:active {
      opacity:         0.8;
      text-decoration: none;
    }
  }
  .value {
    width: 100%;
  }

}


.emSetupValue {
  width: 100%;
}
