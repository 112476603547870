@import "../../../assets/scss/variable";

.help {
  .helpTitle, .helpButtons {
    margin:          4.375rem 0;
    width:           100%;
    text-align:      right;
    display:         flex;
    flex-wrap:       wrap;
    justify-content: space-between;
    align-items:     center;
    h2 {
      margin-top:    0;
      margin-bottom: 0;
    }
    button {
      margin-right: 0;
    }
  }
  .helpInner {
    margin-left: 3.125rem;
  }
  .helpList {
    color:         #9d9cc6;
    font-weight:   bold;
    font-size:     1.5rem;
    line-height:   1.5;
    margin-bottom: 2rem;
    span {
      font-weight:  100;
      padding-left: 2rem;
    }
    li {
      position:     relative;
      padding-left: 2.5rem;
      color:        $secondColor;
    }
    &.map li {
      &:before {
        content:   url("../../../assets/img/mapMarker.svg");
        position:  absolute;
        left:      0;
        top:       50%;
        transform: translateY(-50%);
        width:     1rem;
      }
    }
    &.telephone li {
      &:before {
        content:   url("../../../assets/img/telephoneHandle.svg");
        position:  absolute;
        left:      0;
        top:       50%;
        transform: translateY(-50%);
        width:     1rem;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .help {
    .helpInner {
      margin-left: 0.625rem;
    }
  }
}
