@import "../../../assets/scss/variable";

.tableRow {
  display:               grid;
  grid-template-columns: minmax(10.3125rem, 50%) minmax(20.375rem, 50%);
  padding-right:         0;
  margin:                1.5rem 0;
  min-height:            2.5rem;
  align-items:           center;
  .macDns {
    display:               grid;
    grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
    gap:                   0.75rem;
    input {
      padding:    0.75rem 0 0.75rem 0.75rem;
      text-align: center;
    }
    .sntp {
      display:         flex;
      flex-direction:  row-reverse;
      align-items:     center;
      justify-content: flex-end;
      padding-top:     1.25rem;
    }
  }
  .select {
    background:    #f2f4f8;
    padding:       0 0.6rem 0 4rem;
    border:        none;
    border-radius: 0.625rem;
    height:        3rem;
    select {
      background: transparent;
    }
  }
  .field {
    position: relative;
    .validation {
      position:  absolute;
      color:     $redColor;
      bottom:    -1rem;
      left:      0;
      z-index:   1;
      font-size: 0.75rem;
    }
  }
}

.networkValue {
  width: 100%;
}
