@import "../../../../../assets/scss/variable";

.tableRow {
  margin: 0 5px;
  background-color: $lightBlueColor;
  &:nth-child(1) {
    font-size: 1.125rem;
  }
  .tableCell {
    margin:          0;
    border:          0.03125rem solid #b7c9ff;
    background:      $whiteColor;
    font-size:       1rem;
    line-height:     1.67;
    min-height:      3.125rem;
    display:         flex;
    flex-direction:  column;
    align-items:     flex-start;
    justify-content: center;
    padding:         0.625rem;
    border-radius:   0;
    width:           100%;
    &:nth-child(1) {
      font-weight: bold;
      font-size:   1.25rem;
      color:       $mainColor;
      align-items: center;
    }
  }
  .bold {
    font-weight: bold;
  }
  .type {
    padding:    0 0.625rem;
    background: $whiteColor;
    border:     0.03125rem solid #b7c9ff;
    select {
      width:         100%;
      background:    transparent;
      border-radius: 0;
    }
  }
}

.TopRowButtons_tableRow__bobpm {
  margin: 0 5px;
}




