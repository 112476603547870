@import "../../../../assets/scss/variable";
@import "../../../common/Button/Button.module";

.wrapper {
  background:    $whiteColor;
  border-radius: 1.25rem;
  padding:       2.5rem;
  width:         100%;
  margin-top:    1.875rem;
  position:      relative;
  z-index:       1;
  .table {
    overflow: auto;
    .tableBody {
      line-height: 1.5;
      display:     grid;
    }
    .tableRow {
      background-color:      $lightBlueColor;
      display:               grid;
      grid-template-columns: repeat(auto-fit, minmax(7.5rem, 1fr));
      grid-auto-flow:        column;
      .tableCell {
        margin-bottom:   0.0625rem;
        background:      $whiteColor;
        font-size:       1rem;
        line-height:     1.67;
        min-height:      4.375rem;
        display:         flex;
        flex-direction:  column;
        align-items:     center;
        justify-content: center;
        padding-left:    0.625rem;
        text-align:      center;
        border-radius:   0;
        min-width:       7.5rem;
      }
      .inputWrapper {
        position:        relative;
        margin-bottom:   0.0625rem;
        background:      #fff;
        font-size:       1rem;
        line-height:     1.67;
        min-height:      4.375rem;
        display:         flex;
        flex-direction:  column;
        align-items:     center;
        justify-content: center;
        padding-left:    0.625rem;
        text-align:      center;
        border-radius:   0;
        &:before {
          content:       '';
          background:    $veryLightBlueColor;
          width:         80%;
          height:        57.15%;
          position:      absolute;
          z-index:       0;
          left:          50%;
          top:           50%;
          transform:     translate(-50%, -50%);
          border-radius: 0.625rem;
        }
      }
    }
  }

}

.tableRowWrapper:nth-child(1) {
  .tableCell:nth-child(1) {
    align-items: flex-start;
  }
}

.tableRowWrapper {
  .tableRow:nth-child(1) {
    .tableCell {
      font-size:   1.125rem;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 1.25rem;
  }
}


.tableBody {
  grid-template-columns: 7.5rem repeat(auto-fit, minmax(22.5rem, 1fr));
  grid-gap:              1.5rem 0;
}


// BEGIN EM12
:global {
  .utilitiesTable .EM12 {
    grid-template-columns: 7.5rem repeat(auto-fit, minmax(7.5rem, 1fr));
    grid-gap:              1.5rem 0;
  }
}

@media screen and (max-width: 2050px) {
  :global {
    .utilitiesTable .EM12 {
      grid-template-columns: 14.28% 14.28% 14.28% 14.28% 14.28% 14.28% 14.28%;
      .tableRowWrapper {
        &:nth-child(8) {
          grid-column: 2;
        }
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  :global {
    .utilitiesTable .EM12 {
      grid-template-columns: 20% 20% 20% 20% 20%;
      .tableRowWrapper {
        &:nth-child(10) {
          grid-column: 2;
        }
        &:nth-child(8) {
          grid-column: 4;
        }
        &:nth-child(6) {
          grid-column: 2;
        }
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  :global {
    .utilitiesTable .EM12 {
      grid-template-columns: 25% 25% 25% 25%;
      .tableRowWrapper {
        &:nth-child(11) {
          grid-column: 2;
        }
        &:nth-child(10) {
          grid-column: 4;
        }
        &:nth-child(8) {
          grid-column: 2;
        }
        &:nth-child(6) {
          grid-column: 3;
        }
        &:nth-child(5) {
          grid-column: 2;
        }
      }
    }
  }
}

// END EM12


// BEGIN EM8
:global {
  .utilitiesTable .EM8 {
    grid-template-columns: 7.5rem repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap:              1.5rem 0;
  }
}

@media screen and (max-width: 1600px) {
  :global {
    .utilitiesTable .EM8 {
      grid-template-columns: 7.5rem calc(50% - 3.75rem) calc(50% - 3.75rem);
      .tableRowWrapper {
        &:nth-child(4) {
          grid-column: 2;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  :global {
    .utilitiesTable .EM8 {
      grid-template-columns: 7.5rem calc(100% - 7.5rem);
      .tableRowWrapper {
        &:nth-child(5) {
          grid-column: 2;
        }
        &:nth-child(4) {
          grid-column: 2;
        }
        &:nth-child(3) {
          grid-column: 2;
        }
      }
    }
  }
}

// END EM8


// BEGIN EM4
:global {
  .utilitiesTable .EM4 {
    grid-template-columns: 7.5rem repeat(auto-fit, minmax(15rem, 1fr));
    grid-gap:              1.5rem 0;
  }
}

@media screen and (max-width: 1100px) {
  :global {
    .utilitiesTable .EM4 {
      grid-template-columns: 7.5rem calc(100% - 7.5rem);
      .tableRowWrapper {
        &:nth-child(3) {
          grid-column: 2;
        }
      }
    }
  }
}

// END EM4