@import "../../common/Button/Button.module";

.wrapper {
  background:    $whiteColor;
  border-radius: 1.25rem;
  padding:       2.5rem;
  width:         100%;
  margin-top:    1.875rem;
  position:      relative;
  z-index:       1;
  .table {
    overflow:      auto;
    padding-right: 10%;
  }
}

.networkButtons, .networkTitle {
  margin:          4.375rem 0;
  width:           100%;
  text-align:      right;
  display:         flex;
  flex-wrap:       wrap;
  justify-content: flex-end;
  align-items:     center;
  position:        relative;
  .disabled {
    user-select:    none;
    pointer-events: none;
    opacity:        .8;
    filter:         grayscale(.4);
  }
}

.networkTitle {
  justify-content: space-between;
  h2 {
    margin-top:    0;
    margin-bottom: 0;
  }
  button {
    margin-right: 0;
  }
}

@media screen and (max-width: 778px) {
  .networkButtons, .networkTitle {
    margin: 4.375rem 0 1.875rem 0;
  }
  .wrapper {
    padding: 1.25rem;
  }
}
