@import "../../../assets/scss/variable";
@import "../../common/Button/Button.module";

@media (any-hover: hover) {
  .wrapper {
    .table .tableRow.link a:hover {
      text-decoration: none;
    }
  }
}

.wrapper {
  background:    $whiteColor;
  border-radius: 1.25rem;
  padding:       0 2.5rem;
  width:         35%;
  margin-top:    1.875rem;
  position:      relative;
  z-index:       1;
  min-width:     34.375rem;
  .table {
    overflow: auto;
    .tableRow {
      display:               grid;
      grid-template-columns: minmax(10rem, 50%) minmax(13.75rem, 50%);
      margin:                1.5rem 0;
      min-height:            2.5rem;
      font-size:             1.25rem;
      align-items:           center;
      .select, input {
        padding:         0;
        text-align:      center;
        text-align-last: center;
      }
      &.link a {
        color:           #009cff;
        text-decoration: underline;
        &:active {
          opacity:         0.8;
          text-decoration: none;
        }
      }
    }
    .select {
      background:    #f2f4f8;
      padding:       0 0.6rem 0 4rem;
      border:        none;
      border-radius: 0.625rem;
      height:        3rem;
      select {
        background: transparent;
      }
    }
    .value {
      width: 100%;
    }
  }
}


@media screen and (max-width: 768px) {
  .wrapper {
    padding:   1.25rem;
    width:     100%;
    min-width: 18.75rem;
  }
}