@import "../../../../../assets/scss/variable";

.wrapper {
  background:    $whiteColor;
  border-radius: 1.25rem;
  padding:       2.5rem;
  width:         100%;
  margin-top:    1.875rem;
  overflow:      auto;
  position:      relative;
  z-index:       1;
  .table {
    overflow: auto;
    .tableHeader {
      font-weight:           bold;
      color:                 $mainColor;
      display:               grid;
      grid-template-columns: repeat(auto-fit, minmax(9.1875rem, 1fr));
      grid-auto-flow:        column;
    }
    .tableRow, .tableHeader {
      font-size:   1.125rem;
      line-height: 1.67;
      .tableCell {
        display:         flex;
        justify-content: center;
        background:      white;
        border:          none;
        min-height:      3.75rem;
        height:          3.75rem;
        line-height:     3.75rem;
        padding:         0;
        align-items:     center;
        min-width:       9.1875rem;
        position:        relative;
        input, select {
          width:      100%;
          padding:    0.75rem;
          background: #f8fcff;
        }
      }
    }
    .tableBody {
      display:               grid;
      grid-template-columns: repeat(auto-fit, minmax(9.1875rem, 1fr));
      grid-auto-flow:        column;
      .tableRow {
        border-bottom:    0.0625rem solid $lightBlueColor;
        background-color: #dee8f0;
        .tableCell {
          padding-left:    1.25rem;
          font-size:       1.125rem;
          justify-content: center;
          margin-bottom:   0.0625rem;
          img {
            width: 24px;
          }
        }
      }
    }
  }
}


.siteSetupButtonsWrapper {
  display:       flex;
  flex-wrap:     wrap;
  margin-bottom: 1.25rem;
  align-items:   center;
  .siteSetupButtons {
    border-radius:   1.25rem;
    display:         flex;
    justify-content: flex-start;
    align-items:     center;
    border:          0.0625rem solid $mainColor;
    color:           $mainColor;
    background:      transparent;
    box-shadow:      none;
    transition:      .2s ease-in-out;
    min-height:      2.5rem;
    height:          2.5rem;
    padding:         0 1.25rem;
    font-size:       1.125rem;
    margin-right:    2.5rem;
    margin-bottom:   1.25rem;
    position:        relative;
    input, select {
      color:      $mainColor;
      background: transparent;
      border:     none;
      box-shadow: none;
      padding:    0 0.5rem;
      &[disabled] {
        pointer-events: none;
        opacity:        .5;
      }
    }
  }
  p {
    margin-right: 0.5rem;
  }
  .fader {
    display:         flex;
    align-items:     center;
    justify-content: flex-start;
    margin-bottom:   1.25rem;
    margin-right:    2.5rem;
    > div {
      transform: scale(0.6);
    }
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding: 1.25rem;
  }
}