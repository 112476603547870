@import "../../../assets/scss/variable";

.headerWrapper {
  height: 100%;
  .header {
    position:   fixed;
    top:        0;
    left:       0;
    z-index:    10;
    background: $mainColor;
    padding:    3.125rem;
    height:     100%;
    transition: .4s;
    transform:  translateX(0);
    .logo {
      position:        relative;
      display:         flex;
      align-items:     center;
      justify-content: flex-start;
      img {
        width: 2.625rem;
      }
      .logoText {
        position: relative;
        h1 {
          color: $whiteColor;
        }
        p {
          margin:         0;
          position:       absolute;
          left:           0;
          bottom:         1.375rem;
          font-size:      0.8125rem;
          line-height:    2.14;
          color:          $whiteColor;
          text-transform: uppercase;
          transform:      translateY(100%);
          letter-spacing: 0.1875rem;
        }
      }
      .logoImage {
        margin-left: 0.3125rem;
        margin-top:  0.375rem;
      }
    }
    .headerMenu {
      width:  100%;
      height: 100%;
      .headerList {
        width:        100%;
        height:       100%;
        margin-top:   0;
        padding-left: 2.5rem;
        position:     relative;
      }

    }
  }
  .burgerMenu {
    display:          none;
    position:         fixed;
    top:              0.9375rem;
    right:            2.5rem;
    z-index:          30;
    width:            3.75rem;
    height:           3.75rem;
    border-radius:    50%;
    background-color: rgba(255, 255, 255, 0.5);
    transition:       0.4s;
    box-shadow:       none;
    border:           none;
    cursor:           pointer;
    &:hover, &:active {
      opacity: .7;
    }
    .burgerMenuLines, .burgerMenuLines:after, .burgerMenuLines:before {
      position:         absolute;
      width:            2rem;
      height:           0.1875rem;
      background-color: $mainColor;
      left:             0;
      transition:       .4s;
    }
    .burgerMenuLines {
      top:       50%;
      left:      50%;
      transform: translate(-50%, -50%);
      &:after {
        content: '';
        top:     -0.625rem;
      }
      &:before {
        content: '';
        top:     0.625rem;
      }
    }
    &.active {
      .burgerMenuLines {
        background-color: transparent;
        &::before {
          top:       0;
          transform: rotate(-45deg);
        }
        &::after {
          top:       0;
          transform: rotate(45deg);
        }
      }
    }
  }
}


@media screen and (max-width: 992px) {
  .headerWrapper {
    .header {
      transform: translate(-100%);
      &.active {
        transform: translateX(0%);
      }
    }
    .burgerMenu {
      display: block;
    }
  }
}

@media screen and (max-width: 768px) {
  .headerWrapper {
    .burgerMenu {
      right: 0.9375rem;
    }
  }
}

@media screen and (max-width: 567px) {
  .headerWrapper {
    .header {
      .logo {
        top:  -2.1875rem;
        left: -1.875rem;
      }
    }
  }
}