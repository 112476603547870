@import "../../../../assets/scss/variable";

.menuItem {
  position: absolute;
  left:     0;
  @for $i from 0 through 7 {
    &:nth-child(#{$i}) {
      top: calc(4.375rem + (3.75rem * #{$i}));
    }
  }
  a {
    display:         flex;
    align-items:     center;
    justify-content: left;
    height:          1.875rem;
    svg {
      width:        1.25rem;
      margin-right: 1.5rem;
    }
    &.active {
      font-size: 1.5rem;
      color:     $whiteColor;
      svg {
        fill: $whiteColor;
      }
      &:before {
        content:       '';
        position:      absolute;
        background:    $whiteColor;
        width:         0.625rem;
        border-radius: 0 0.625rem 0.625rem 0;
        height:        3.125rem;
        left:          -3.125rem;
        top:           -0.625rem;
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .menuItem {
    @for $i from 0 through 7 {
      &:nth-child(#{$i}) {
        top: calc(3.75rem * #{$i} - 1.875rem);
      }
    }
  }
}