.app {
  display:         flex;
  align-items:     flex-start;
  justify-content: flex-start;
  height:          100%;
}

.main {
  padding:     2.5rem;
  width:       calc(100% - 303px);
  margin-left: auto;
  position:    relative;
  min-height:  100%;
  .loadingWrapper {
    position:    absolute;
    top:         0;
    left:        0;
    width:       100%;
    height:      100%;
    z-index:     2;
    background:  rgba(0, 0, 0, 0.3);
    user-select: none;
    display:     none;
    .loading {
      position:  absolute;
      top:       50%;
      left:      50%;
      transform: translate(-50%, -50%);
      width:     9.375rem;
      height:    9.375rem;
    }
    &.show {
      display: block;
    }
  }
}

@media screen and (max-width: 992px) {
  .main {
    width:       calc(100%);
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 0.9375rem;
  }
}