$bgColor: #eef2f5;
$whiteColor: #fff;
$grayColor: #f2f4f8;
$blackColor: #111;
$lightBlackColor: #1e1e1e;
$mainColor: #303650;
$secondColor: #8e8dbe;
$lightBlueColor: #dee8f0;
$veryLightBlueColor: #f8fcff;
$redColor: red;
