@import "../../../assets/scss/variable";

.wrapper {
  background:    $whiteColor;
  border-radius: 1.25rem;
  padding:       2.5rem;
  width:         100%;
  margin-top:    1.875rem;
  .table {
    overflow: auto;
    .tableBody {
      font-size:             1.25rem;
      line-height:           1.5;
      display:               grid;
      grid-template-columns: repeat(auto-fit, minmax(11.875rem, 1fr));

    }
    .tableRow {
      .tableCell {
        margin:    0.75rem 0;
        min-width: 11.875rem;
      }
      .tableCell:not(:first-child) {
        font-weight: bold;
      }
    }
  }
  h3 {
    margin: 0 0 2.5rem 1.875rem;
  }
}

.bluehref {
  font-size:       1.125rem;
  text-decoration: none;
  color:           $secondColor !important;
  font-weight:     bold;
  line-height:     1.25;
}


@media screen and (max-width: 768px) {
  .wrapper {
    padding: 1.25rem;
  }
}
