@import "assets/scss/variable";

@media (any-hover: hover) {
  a:hover span {
    font-size: 1.5rem;
    color:     $whiteColor;
  }
  a:hover svg {
    fill: $whiteColor;
  }
}

body, html {
  margin:  0;
  padding: 0;
  height:  100%;
}

html {
  font-size: 16px;
}

body {
  background: $bgColor;
}

* {
  box-sizing:  border-box;
  font-family: 'Proxima Nova', sans-serif;
  padding:     0;
  margin:      0;
  outline:     none;
}

#root {
  height: 100%;
}

a {
  font-size:       1.125rem;
  text-decoration: none;
  color:           $whiteColor;
  font-weight:     bold;
  line-height:     1.25;
  svg {
    fill: $whiteColor;
  }
  &:active span {
    opacity:   .7;
    font-size: 1.5rem;
    color:     $whiteColor;
  }
  &:active svg {
    opacity: .7;
    fill:    $whiteColor;
  }
}

h1 {
  font-size:   2.125rem;
  line-height: 1.66;
  font-weight: 800;
  font-style:  italic;
}

h2 {
  font-size:   2.25rem;
  color:       $lightBlackColor;
  margin:      4.375rem 0.625rem 4.375rem 4.375rem;
  min-height:  2.5rem;
  line-height: 5.5rem;
}

h3 {
  font-size:   1.5rem;
  line-height: 1.25;
  color:       $lightBlackColor;
  margin:      0 0 3.125rem 0;
}

ul {
  list-style-type: none;
  li {
    font-size:   1.125rem;
    line-height: 1.67;
    color:       $whiteColor;
  }
}

input, select {
  background:    $grayColor;
  border:        none;
  border-radius: 0.625rem;
  font-size:     1.125rem;
  height:        3rem;
}

input {
  padding: 0.75rem 0 0.75rem 4.375rem;
}

select {
  padding: 0;
}

button[disabled] {
  display: none;
}

input[disabled], select[disabled] {
  cursor: default;
}


.checkbox {
  width:  1.875rem;
  height: 1.875rem;
  input[type='checkbox'] {
    padding:       0;
    height:        initial;
    width:         initial;
    margin-bottom: 0;
    display:       none;
    cursor:        pointer;
    &:checked + label:after {
      content:         '';
      background:      url("./assets/img/checkBox.svg") no-repeat center;
      background-size: contain;
      display:         inline-block;
      position:        absolute;
      top:             0;
      left:            -0.25rem;
      width:           100%;
      height:          100%;
      cursor:          pointer;
    }
  }
  label {
    position:     relative;
    width:        1.875rem;
    height:       1.875rem;
    display:      inline-block;
    padding-left: 2.5rem;
    line-height:  1.875rem;
    &:before {
      content:         '';
      background:      url("./assets/img/checkBox-empty.svg") no-repeat center;
      position:        absolute;
      top:             0;
      left:            0;
      background-size: contain;
      display:         inline-block;
      cursor:          pointer;
      width:           1.625rem;
      height:          1.875rem;
    }
    &:hover, &:active {
      opacity: .8;
    }
  }

}

input[type='file'] {
  display: none;
}

.notice, .error {
  position:    absolute;
  left:        0;
  bottom:      -1rem;
  font-size:   1.15rem;
  color:       #2ac92a;
  margin-left: 4.375rem;
  transition:  .4s ease-in-out;
}
.error {
  color: $redColor;
}

.validation {
  border: 1px solid $redColor;
}

::-webkit-scrollbar { width: 0.3125rem; height: 0.3125rem;}

::-webkit-scrollbar-button { background-color: transparent; }

::-webkit-scrollbar-track { background-color: transparent;}

::-webkit-scrollbar-track-piece { background-color: transparent;}

::-webkit-scrollbar-thumb { height: 3.125rem; background-color: #d8dfff; border-radius: 0.3125rem;}

::-webkit-scrollbar-corner { background-color: transparent;}


::-webkit-resizer { background-color: #d8dfff;}

.react-tabs__tab--selectedold {
  color:      $lightBlackColor;
  background: $whiteColor;
}

.react-tabs__tab--selected {
  background: $whiteColor;
  border-color: #aaa;
  color: $lightBlackColor;
  border-radius: 10px 10px 0 0;
}

.react-tabs__tab{
  display: inline-block;
  border: 1px solid #00000059;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  border-radius: 10px 10px 0 0;
  color: #444444;
}

.react-tabs__tab--disabled {
  color: #444444;
  cursor: default;
  border-radius: 10px 10px 0 0;
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
  h2 {
    margin: 1.25rem 0.625rem 1.25rem 1.25rem;
  }
  .notice {
    margin-left: 1.25rem;
  }
}

@media screen and (max-width: 567px) {
  html {
    font-size: 10px;
  }
}

@media screen and (max-width: 360px) {
  html {
    font-size: 9px;
  }
}