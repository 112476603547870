@import "../../../assets/scss/variable";
@import "../../common/Button/Button.module";

.emSetupButtons, .emSetupTitle {
  margin:          4.375rem 0 9.375rem 0;
  width:           100%;
  text-align:      right;
  display:         flex;
  flex-wrap:       wrap;
  justify-content: flex-end;
  align-items:     center;
  position:        relative;
}

.emSetupButtons {
  margin: 6.875rem 0;
  .disabled {
    user-select:    none;
    pointer-events: none;
    opacity:        .8;
    filter:         grayscale(.4);
  }
}

.emSetupTitle {
  justify-content: space-between;
  h2 {
    margin-top:    0;
    margin-bottom: 0;
  }
  button {
    margin-right: 0;
  }
}

.emSetupTabsWrapper {
  position: relative;
  .emSetupTabs {
    position:      absolute;
    z-index:       0;
    left:          0;
    top:           1.25rem;
    transform:     translateY(-100%);
    display:       inline-flex;
    font-weight:   bold;
    background:    #f6f8fa;
    color:         $lightBlackColor;
    border-radius: 1.25rem 1.25rem 0 0;
    font-size:     1.5rem;
    line-height:   1.25;
    cursor:        pointer;
    li {
      padding:       1.25rem 3.75rem 2.5rem 3.75rem;
      border-radius: 1.25rem 1.25rem 0 0;
      color:         $mainColor;
    }
  }
}

.wrapper {
  background:    $whiteColor;
  border-radius: 1.25rem;
  padding:       2.5rem;
  width:         100%;
  margin-top:    1.875rem;
  position:      relative;
  z-index:       1;
  .table {
    overflow: auto;
  }
}

.tableTopRowButtons {
  display:               grid;
  grid-template-columns: repeat(auto-fit, minmax(8rem, 1fr));
  line-height:           1.5;
  grid-gap:              0.5rem 0;
}

@media screen and (max-width: 1200px) {

  .emSetupTabsWrapper {
    .emSetupTabs {
      li {
        padding: 1.25rem 1.875rem 2.5rem 1.875rem;
      }
    }
  }

}

@media screen and (max-width: 778px) {
  .wrapper {
    padding: 1.25rem;
  }
}

@media screen and (max-width: 568px) {

  .emSetupTabsWrapper {
    .emSetupTabs {
      li {
        padding: 1.25rem 0.625rem 2.5rem 0.625rem;
      }
    }
  }
  .emSetupButtons {
    margin: 1.25rem 0;
  }

}

// BEGIN EM12
@media screen and (max-width: 2000px) {
  :global {
    .emSetup .EM12 {
      grid-template-columns: 33.33% 33.33% 33.33%;
    }
  }
}

// END EM12


// BEGIN EM8
@media screen and (max-width: 1500px) {
  :global {
    .emSetup .EM8 {
      grid-template-columns: 25% 25% 25% 25%;
    }
  }
}

@media screen and (max-width: 420px) {
  :global {
    .emSetup .EM8 {
      grid-template-columns: 50% 50%;
    }
  }
}

// END EM8


// BEGIN EM4
@media screen and (max-width: 420px) {
  :global {
    .emSetup .EM4 {
      grid-template-columns: 50% 50%;
    }
  }
}

// END EM4