@import "../../../../../assets/scss/variable";
@import "../../../../common/Button/Button.module";

.wrapper {
  background:    $whiteColor;
  border-radius: 1.25rem;
  padding:       0 2.5rem;
  width:         35%;
  margin-top:    1.875rem;
  position:      relative;
  z-index:       1;
  min-width:     34.375rem;
  .table {
    overflow: auto;
    .tableRow {
      display:               grid;
      grid-template-columns: minmax(10rem, 50%) minmax(13.75rem, 50%);
      margin:                1.5rem 0;
      min-height:            2.5rem;
      font-size:             1.25rem;
      align-items:           center;
      position:              relative;
      .select, input {
        padding:         0;
        text-align:      center;
        text-align-last: center;
        width:           100%;
      }
      .field {
        position: relative;
        .validation {
          position:  absolute;
          color:     $redColor;
          bottom:    -1rem;
          left:      0;
          z-index:   1;
          font-size: 0.75rem;
        }
      }
    }
    .select {
      background:    #f2f4f8;
      padding:       0 0.6rem 0 4rem;
      border:        none;
      border-radius: 0.625rem;
      height:        3rem;
      select {
        background: transparent;
      }
    }
    .value {
      width: 100%;
    }
  }
}
