@import "../../../assets/scss/variable";

.primaryBtn {
  border-radius:   1.25rem;
  background:      $mainColor;
  color:           $whiteColor;
  min-width:       11.5625rem;
  display:         flex;
  align-items:     center;
  justify-content: center;
  height:          3rem;
  font-weight:     bold;
  font-size:       1.25rem;
  line-height:     0.104375rem;
  border:          0.0625rem solid $mainColor;
  transition:      .2s ease-in-out;
  cursor:          pointer;
  margin-right:    3.125rem;
  padding:         0 1.25rem;
  margin-bottom:   1.25rem;
  margin-top:      1.25rem;
  &:last-child(1) {
    margin-right: 0;
  }
  &:hover {
    background: transparent;
    color:      $mainColor;
  }
  &:active {
    background: transparent;
    color:      $mainColor;
    opacity:    .7;
  }
  &.small {
    padding:   0;
    margin:    0 auto 0 auto;
    min-width: 6.25rem;
  }
  &.empty {
    padding:    0;
    margin:     0 auto 0 auto;
    min-width:  6.25rem;
    background: $veryLightBlueColor;
    border:     $veryLightBlueColor;
    color:      $veryLightBlueColor;
    cursor:     default;
  }
}


@media screen and (max-width: 768px) {
  .primaryBtn {
    margin-right: 0.625rem;
    min-width:    7.5rem;
  }
}