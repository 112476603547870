@import "../../../assets/scss/variable";
@import "../../common/Button/Button.module";

.siteSetup {
  .siteSetupTitle {
    margin:          4.375rem 0;
    width:           100%;
    text-align:      right;
    display:         flex;
    flex-wrap:       wrap;
    align-items:     center;
    justify-content: space-between;
    position:        relative;
    h2 {
      margin-top:    0;
      margin-bottom: 0;
    }
    button {
      margin-right: 0;
    }
  }
  .siteSetupInner {
    display:         flex;
    align-items:     flex-end;
    justify-content: space-between;
    margin-bottom:   7.5rem;
    .siteSetupButtons {
      display:         flex;
      flex-wrap:       wrap;
      flex-direction:  row-reverse;
      align-items:     flex-end;
      justify-content: flex-start;
      .primaryBtn {
        margin: 1.875rem 0 1.25rem 3.125rem;
      }
      .disabled {
        user-select:    none;
        pointer-events: none;
        opacity:        .8;
        filter:         grayscale(.4);
      }
    }
  }
}


.siteSetupTabsWrapper {
  position:      relative;
  margin-bottom: 0;
  .siteSetupTabs {
    overflow:      auto;
    max-width:     90%;
    position:      absolute;
    z-index:       0;
    left:          0;
    top:           1.25rem;
    transform:     translateY(-100%);
    display:       inline-flex;
    font-weight:   bold;
    background:    #f6f8fa;
    color:         $lightBlackColor;
    border-radius: 1.25rem 1.25rem 0 0;
    font-size:     1.5rem;
    line-height:   1.25;
    cursor:        pointer;
    li {
      min-width:     7.5rem;
      padding:       1.25rem 2.8% 2.5rem 2.8%;
      border-radius: 1.25rem 1.25rem 0 0;
      text-align:    center;
      color: $mainColor;
    }
  }
}


@media screen and (max-width: 1200px) {
  .siteSetup {
    .siteSetupInner {
      flex-wrap: wrap;
      .siteSetupButtons {
        flex-direction: row;
        button {
          margin-left:  0;
          margin-right: 2.5rem;
        }
      }
    }
  }

}


@media screen and (max-width: 778px) {
  .siteSetup {
    .siteSetupTitle {
      margin: 4.375rem 0 1.875rem 0;
    }
  }
}

