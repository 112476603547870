@import "../../../assets/scss/variable";
@import "../../common/Button/Button.module";

.wrapper {
  background:    $whiteColor;
  border-radius: 1.25rem;
  padding:       1.25rem 2.5rem;
  width:         35%;
  margin-top:    1.875rem;
  position:      relative;
  z-index:       1;
  min-width:     34.375rem;
  .table {
    .tableRow {
      display:               grid;
      grid-template-columns: 50% 50%;
      margin:                1.5rem 0;
      min-height:            2.5rem;
      font-size:             1.25rem;
      align-items:           center;
    }
    .utilitiesValue {
      width: 100%;
    }
  }
}

.utilitiesButtons, .utilitiesTitle {
  margin:          4.375rem 0;
  width:           100%;
  text-align:      right;
  display:         flex;
  flex-wrap:       wrap;
  justify-content: flex-end;
  align-items:     center;
  position:        relative;
  .disabled {
    user-select:    none;
    pointer-events: none;
    opacity:        .8;
    filter:         grayscale(.4);
  }
}

.utilitiesTitle {
  justify-content: space-between;
  h2 {
    margin-top:    0;
    margin-bottom: 0;
  }
  button {
    margin-right: 0;
  }
}


@media screen and (max-width: 768px) {
  .wrapper {
    padding: 1.25rem;
  }
}